@use "breakpoints";
@use "colors";
@use "layout";
@use "typography";
@import "mixins";
@import "custom";

.global-footer {
  background-color: $white;
  border-block-start: 1px solid rgba(0, 0, 0, 10%);
  padding-block: 40px 152px;
  position: relative;
  user-select: none;
  z-index: 1;
}

.content {
  @include layout.module-content;
  @include typography.body-small;
}

.nav-title {
  color: colors.$black;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
}

.nav-link {
  background-color: transparent;
  border: 0;
  color: rgba(0, 0, 0, 60%);
  margin-block-start: 20px;
  margin-inline-end: auto;
  outline: none;
  padding-block: 3px;
  transition: all 300ms;

  &:focus {
    outline: 1px solid colors.$black;
  }

  &:hover {
    color: colors.$black;
  }
}

.about-links {
  display: flex;
  flex-direction: column;
}

.social-links {
  display: flex;
  margin-block: 40px 20px;

  .social-icon {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 100%;
    block-size: 40px;
    display: block;
    inline-size: 40px;
    outline: none;
    overflow: hidden;
    padding-block-start: 40px;
    transition: all ease-in-out 100ms;

    & + .social-icon {
      margin-inline-start: 20px;
    }

    &:hover {
      transform: scale(1.1);
      transition: all ease-in-out 100ms;
    }
  }
}

.legal-links {
  display: flex;
  flex-direction: column;
}

@media (width >= breakpoints.$sm) {
  .global-footer {
    padding-block: 58px 80px;
  }

  .content {
    flex-flow: row wrap;
  }

  .about-links {
    flex: 1 1 50%;
  }

  .social-links {
    margin-block: 0;
    margin-inline-start: auto;
  }

  .legal-links {
    flex-flow: row wrap;
    justify-content: flex-end;
    margin-block-start: 74px;
    margin-inline-start: auto;

    .nav-link {
      margin-inline: 20px 0;
    }
  }
}
